// components/CarSearchForm.tsx

'use client';

import React, { useState } from 'react';
import ModalSelectCar, { CarData } from './ModalSelectCar';

export default function CarSearchForm() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [carModel, setCarModel] = useState<CarData | null>(null);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setIsModalOpen(true);
  };

  const handleCarSelect = (car: CarData) => {
    setCarModel(car);
    setIsModalOpen(false);
    setInputValue(car.car);
  };

  return (
    <>
      <div className="domain-search domain-search-two">
        <form>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Digite modelo do carro para consultar o preço"
              value={inputValue}
              onChange={handleInputChange}
            />
             <button type="submit" className="btn btn-gradient">
                Consultar preço
             </button>   
          </div>
        </form>
      </div>

      {/* Modal de Seleção de Carro */}
      {isModalOpen && (
        <ModalSelectCar
          onSelect={handleCarSelect}
          onClose={() => setIsModalOpen(false)}
          initialSearchTerm={inputValue}
        />
      )}
    </>
  );
}
